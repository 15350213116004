const state = () => ({
  is3DsInProcess: false,
});

const mutations = {
  setIs3DsInProcess(state, payload) {
    state.is3DsInProcess = payload;
  },
};

const actions = {
  rollbackDefaultTheme({ commit, state }) {
    if (state.is3DsInProcess) commit('setIs3DsInProcess', false);
  },
};

export default {
  state,
  actions,
  mutations,
};
