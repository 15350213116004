import Vue from 'vue';
import Vuex from 'vuex';

import global from './global';

import widget from './widget';
import result from './result';

Vue.use(Vuex);

const store = new Vuex.Store({
  ...global,

  modules: {
    widget,
    result,
  },
});

const createDispatch = () => {
  const dispatch = store.dispatch.bind(store);

  return async function (action, payload, options) {
    return await dispatch(action, payload, options);
  };
};

store.dispatch = createDispatch();

export default store;
